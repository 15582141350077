<template>
    <slot v-bind="{ hidden, select }" />
</template>

<script>
export default {
    name: "MainNavigationItem",

    inject: {
        registerNavigationItem: { default: () => () => {} },
        unregisterNavigationItem: { default: () => () => {} },
        setActive: { default: () => () => {} },
    },

    props: {
        id: Number | String,
        hasSubNav: Boolean,
    },

    data() {
        return {
            hidden: false,
        };
    },

    created() {
        this.registerNavigationItem(this);
    },

    methods: {
        select(event) {
            if (this.hasSubNav) {
                event.preventDefault();
                this.setActive(this);
                return;
            }
        },

        hide(hide) {
            this.hidden = hide;
        },

        getId() {
            return this.id;
        },

        getText() {
            const text = [...this.$el.nextSibling.childNodes].find(
                (child) => child.nodeType === Node.TEXT_NODE
            );
            return text && text.textContent.trim();
        },

        getHref() {
            const href = this.$el.nextSibling.getAttribute("href");
            return href ? href : null;
        },
    },

    beforeUnmount() {
        this.unregisterNavigationItem(this);
    },
};
</script>
