<template>
    <swiper
        class="stage-slider"
        :modules="modules"
        :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
        }"
        :pagination="{
            el: '.slideshow-pagination',
            clickable: true,
        }"
        parallax
        @autoplay-time-left="autoplayTimeLeft"
    >
        <slot />
        <div v-if="hasMultipleChildren" class="slideshow-pagination"></div>
    </swiper>
    <div
        v-if="hasMultipleChildren"
        ref="autoplayprogress"
        class="autoplay-progress"
    >
        <svg viewBox="0 0 16 16">
            <circle
                class="autoplay-progress__circle"
                cx="8"
                cy="8"
                r="7"
                fill="none"
                stroke-width="1"
                pathLength="100"
            ></circle>
        </svg>
    </div>
</template>

<script>
import { Autoplay, Pagination, Parallax } from "swiper";

export default {
    name: "StageSlider",

    props: {
        hasMultipleChildren: {
            type: Boolean,
            default: false,
        },
    },

    data: () => {
        return {
            modules: [Autoplay, Pagination, Parallax],
            swiper: null,
        };
    },

    methods: {
        autoplayTimeLeft(event, timeLeft, progress) {
            if (!this.$refs.autoplayprogress) {
                return;
            }
            const progressCircles =
                this.$refs.autoplayprogress.querySelectorAll(
                    ".autoplay-progress__circle"
                );
            if (!progressCircles) {
                return;
            }

            if (!this.hasMultipleChildren) {
                return;
            }

            progressCircles.forEach((progressCircle) => {
                progressCircle.style.setProperty("--progress", 1 - progress);
            });
        },
    },
};
</script>
<style lang="scss">
@use "@bam/scss" as *;

.autoplay-progress {
    position: absolute;
    left: calc(50% - 15px);
    bottom: 84px;
    z-index: 10;
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
    pointer-events: none;
    display: none;

    @include mq-from(md) {
        display: block;
    }
}

.autoplay-progress circle {
    stroke: v(color-primary);
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100px * (1 - var(--progress)));
}

.stage-slider {
    .slideshow-pagination {
        position: relative;
        bottom: 103px;
        gap: v(size-16);

        .swiper-pagination-bullet {
            width: v(size-10);
            height: v(size-10);

            @include mq-from(md) {
                width: v(size-10);
                height: v(size-10);
            }
        }
    }
}
</style>
