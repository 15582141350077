<template>
    <div :class="['main-navigation', menuClasses]" ref="main-navigation">
        <OnClickOutside @trigger="closeMainMenu">
            <span
                v-if="isInMobileNavBp"
                class="main-navigation__button"
                @click="toggleMainMenu"
            >
                <slot name="icon-menu" v-bind="{ mainMenuActive }" />
            </span>

            <transition-group
                @after-enter="mainTransitionName = 'forward'"
                @after-leave="onMenuAfterLeave"
                :name="mainNavigationTransitionName"
                tag="div"
                class="main-navigation__nav-wrapper"
            >
                <nav
                    v-show="showMainNav"
                    class="main-navigation__nav"
                    key="mainNav"
                >
                    <slot
                        v-bind="{
                            moreItems,
                            toggleMoreMenu,
                            moreMenuActive,
                            activeMenuId,
                        }"
                    />

                    <template v-if="isInMobileNavBp">
                        <slot name="flyout-footer" />
                    </template>
                </nav>

                <div
                    v-show="subMenuActive"
                    class="main-navigation__flyout"
                    key="subNav"
                >
                    <span
                        v-if="isInMobileNavBp"
                        class="main-navigation__back"
                        @click="goBack"
                    >
                        <slot name="icon-back" />
                    </span>
                    <div
                        v-if="!isInMobileNavBp"
                        class="main-navigation__close"
                        @click="closeSubMenu"
                    >
                        <slot name="icon-close" />
                    </div>
                    <slot
                        name="flyouts"
                        v-bind="{
                            subMenuActive,
                            toggleMainMenu,
                            activeMenuId,
                            subNavigationTransitionName,
                        }"
                    />
                    <slot name="flyout-footer" />
                </div>
            </transition-group>
        </OnClickOutside>
    </div>
</template>

<script>
import { defineComponent, h } from "vue";
import MainNavigationItem from "./MainNavigationItem.vue";
import { OnClickOutside } from "@vueuse/components";

const VNode = defineComponent({
    name: "v-node",
    props: ["vnode"],
    setup(props) {
        return () => h(props.vnode);
    },
});

export default {
    name: "MainNavigation",

    provide() {
        return {
            registerNavigationItem: this.registerNavigationItem,
            unregisterNavigationItem: this.unregisterNavigationItem,
            setActive: this.setActive,
        };
    },

    components: {
        VNode,
        MainNavigationItem,
        OnClickOutside,
    },

    data() {
        return {
            mainMenuActive: false,
            mainTransitionName: "start",

            subTransitionName: "forward",
            navigationItems: [],
            moreItems: [],
            activeMenuId: null,

            subMenuActive: false,
            moreMenuActive: false,

            moved: false,
            menuWidth: 0,
            previousMenuWidth: 0,
            firstRun: true,
            resizeObserver: null,
            isInMobileNavBp: this.$breakpoints.smaller("md"),
        };
    },

    watch: {
        isInMobileNavBp(isSmaller) {
            if (!isSmaller && this.subMenuActive) {
                // this.subMenuActive = false;
            }
        },
    },

    computed: {
        menuClasses() {
            return {};
        },

        lastElement() {
            if (this.navigationItems.length == this.moreItems.length) {
                return null;
            }

            const itemsWithoutMore = this.navigationItems.filter(
                (item) =>
                    !this.moreItems.some(
                        (item2) => item.getId() === item2.getId()
                    )
            );

            return itemsWithoutMore[itemsWithoutMore.length - 1] || null;
        },

        showMainNav() {
            return (
                !this.isInMobileNavBp ||
                (this.isInMobileNavBp &&
                    this.mainMenuActive &&
                    !this.subMenuActive)
            );
        },

        mainNavigationTransitionName() {
            return `main-navigation-${this.mainTransitionName}`;
        },

        subNavigationTransitionName() {
            return `main-navigation-${this.subTransitionName}`;
        },
    },

    async mounted() {
        // const slots = useSlots();

        await this.$nextTick();

        try {
            this.observer = new ResizeObserver((entries) => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                entries.forEach((entry) => {
                    this.menuWidth = entry.contentRect.width;

                    if (
                        this.previousMenuWidth !==
                            entry.contentRect.width ||
                        this.firstRun
                    ) {
                        this.observer.unobserve(this.$el);

                        if (this.firstRun) {
                            this.previousMenuWidth =
                                entry.contentRect.width;
                        }

                        this.moveItem();
                        this.firstRun = false;

                        window.requestAnimationFrame(() => {
                            this.observer.observe(this.$el);
                        });

                    }

                    this.previousMenuWidth = entry.contentRect.width;
                });
            });

            this.observer.observe(this.$el);
        } catch (e) {}
    },

    methods: {
        goBack() {
            this.mainTransitionName = "back";
            this.subMenuActive = false;
        },

        registerNavigationItem(item) {
            this.navigationItems.push(item);
        },

        unregisterNavigationItem(item) {
            // this.navigationItems.push(item);
        },

        setActive(item) {
            this.moreMenuActive = false;

            if (!this.subMenuActive) {
                this.subTransitionName = this.isInMobileNavBp
                    ? "forward"
                    : "start";
                this.mainTransitionName = this.isInMobileNavBp
                    ? "forward"
                    : "start";
                this.subMenuActive = true;
                this.mainMenuActive = true;
                this.activeMenuId = item.getId();
            } else if (
                this.subMenuActive &&
                this.activeMenuId != item.getId()
            ) {
                this.subTransitionName = "forward";
                this.activeMenuId = item.getId();
            } else {
                this.subTransitionName = "none";
                this.mainTransitionName = this.isInMobileNavBp
                    ? "back"
                    : "start";
                this.subMenuActive = false;
            }
        },

        toggleMoreMenu() {
            this.moreMenuActive = !this.moreMenuActive;
        },

        totalWidthOfChildren() {
            if (this.navigationItems.length === 0) {
                return 0;
            }

            const elements = this.$el.querySelectorAll(
                ".main-navigation__nav .main-navigation__item:not(.main-navigation__item--hidden)"
            );

            return Array.from(elements).reduce((total, child) => {
                return total + child.offsetWidth;
            }, 0);
        },

        isOverflown() {
            if (!this.menuWidth) {
                return false;
            }
            return this.menuWidth < this.totalWidthOfChildren() + 10;
        },

        async moveItem() {
            if (this.isInMobileNavBp) {
                this.moreItems.map((i) => i.hide(false));
                this.moreItems = [];
                return;
            }
            if (this.isOverflown()) {
                const lastElement = this.lastElement;

                if (!lastElement) {
                    return;
                }

                this.moreItems.unshift(lastElement);
                lastElement && lastElement.hide(true);
                this.moved = false;

                await this.$nextTick();
                this.moveItem();
            } else if (
                (this.menuWidth > this.previousMenuWidth ||
                    this.firstRun ||
                    this.moved) &&
                this.moreItems.length > 0
            ) {
                const firstElement = this.moreItems[0];
                firstElement.hide(false);
                this.moreItems.shift();
                this.moved = true;

                await this.$nextTick();
                this.moveItem();
            }
        },

        onMenuAfterLeave() {
            if (!this.subMenuActive) {
                this.activeMenuId = null;
            }
        },

        toggleMainMenu() {
            if (this.mainMenuActive) {
                this.closeMainMenu();
            } else {
                this.openMainMenu();
            }
        },

        openMainMenu() {
            this.mainTransitionName = "start";
            this.mainMenuActive = true;
        },

        closeMainMenu() {
            this.mainTransitionName = "start";
            this.mainMenuActive = false;
            this.subMenuActive = false;
        },

        closeSubMenu() {
            this.mainTransitionName = this.isInMobileNavBp ? "back" : "start";
            this.subMenuActive = false;
        },
    },
};
</script>
