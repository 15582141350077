<template>
    <slot
        v-bind="{
            selectSortFilter,
            registerFilterItem,
            itemFilters,
            currentCriteria,
            filteredOut,
        }"
    />
</template>

<script>
export default {
    name: "FilterSortContainer",

    props: {
        filterItems: Array,
    },

    data() {
        return {
            currentCriteria: [],
        };
    },

    computed: {
        itemFilters() {
            const weightedItems = this.filterItems.reduce((carry, current) => {
                if (!carry.hasOwnProperty(current)) {
                    carry[current] = 0;
                }
                carry[current]++;

                return carry;
            }, {});

            const ranked = (obj, num = 1) => {
                const requiredObj = {};
                if (num > Object.keys(obj).length) {
                    return false;
                }
                Object.keys(obj)
                    .sort((a, b) => obj[b] - obj[a])
                    .forEach((key, ind) => {
                        if (ind < num) {
                            requiredObj[key] = obj[key];
                        }
                    });
                return requiredObj;
            };

            let rankedItems = ranked(
                weightedItems,
                Math.min(20, Object.keys(weightedItems).length)
            );

            rankedItems = Object.keys(rankedItems).reduce((ca, cu) => {
                return [...ca, cu];
            }, []);

            return [...new Set(rankedItems)].sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
        },

        filteredItems() {
            return [...this.items].filter((item, index) => {
                if (!this.currentCriteria.length) {
                    return true;
                }

                return this.currentCriteria.every((cc) =>
                    item.criteria.includes(cc)
                );
            });
        },
    },

    methods: {
        registerFilterItem(item) {
            this.filterItems.push(item);
        },

        filteredOut(tags) {
            if (!this.currentCriteria.length) {
                return false;
            }

            return !this.currentCriteria.every((r) => tags.indexOf(r) >= 0);
        },

        selectSortFilter(filter) {
            if (this.currentCriteria.indexOf(filter.toLowerCase()) !== -1) {
                this.currentCriteria = this.currentCriteria.filter(
                    (i) => i != filter.toLowerCase()
                );
            } else {
                this.currentCriteria.push(filter.toLowerCase());
            }
        },
    },
};
</script>
